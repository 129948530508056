import { Component, Vue } from 'vue-property-decorator'
import FormCCSS from '@/components/CCSSForm/CCSS/CCSS.vue'
import CCSS from '@/Models/CCSS'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import router from '@/router'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import menuModule from '@/store/menu'
import Paginator from '@/components/Paginator/Paginator.vue'
import Item from '@/Models/Item'
import Animation from '@/components/Animation/index.vue'

@Component({
  components: {
    FormCCSS,
    Search,
    ModalDelete,
    Header,
    Paginator,
    Animation,
  },
})
export default class ViewCCSS extends Vue {
  showList = true
  data: Array<CCSS> = []
  objCCSS: CCSS = new CCSS()

  isAction = 'add'
  loading = false
  delete_modal_active = false

  url = '/api/ccss/arcr_ccss/'
  params: Array<Item> = []

  perPage = menuModule.perPage_param

  header: Array<any> = [
    {
      field: 'name_client',
      label: 'Nombre cliente',
    },
    {
      field: 'client_member_number',
      label: '# miembro',
    },
    {
      field: 'insurance_number',
      label: '# seguro',
    },
    {
      field: 'status_read',
      label: 'Estado',
    },
    {
      field: 'reported_salary',
      label: 'Salario reportado',
    },
    {
      field: 'monthly_quota',
      label: 'Cuota mensual',
    },
    {
      field: 'account_balance',
      label: 'Saldo cuenta',
    },
    {
      field: 'automatic_charge_applies_read',
      label: 'Cargo Auto',
    },
  ]
  /*  mounted() {
    console.log('xd')
  } */
  editObj(props: any) {
    this.objCCSS = props
    this.showList = false
    this.isAction = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(`/api/ccss/arcr_ccss/${this.objCCSS.id}/`)
      this.data = this.data.filter((a: CCSS) => a.id !== this.objCCSS.id)
      this.delete_modal_active = false
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  changePerPage(range_perPage: any) {
    menuModule.setPerpageParam(String(range_perPage))
    this.perPage = menuModule.perPage_param
  }

  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objCCSS = props
  }
  closeModal() {
    this.objCCSS = new CCSS()
    this.delete_modal_active = false
  }
  document(props: any) {
    this.objCCSS = props
    const id = this.objCCSS.id.toString()
    menuModule.setIdParam(id)
    router.push({ name: 'DocumentCCSS' }).catch(console.log)
  }
  dependent(props: any) {
    this.objCCSS = props
    const id = this.objCCSS.id.toString()
    menuModule.setIdParam(id)
    this.isAction = 'addDependent'
    router.push({ name: 'DependentCCSS' }).catch(console.log)
  }
  openForm() {
    this.showList = false
  }
  cleanForm() {
    ;(this.$refs.header as any).showList = true
    //(this.$refs['headerForm'] as any)['showList'] = true
    this.objCCSS = new CCSS()
    this.showList = true
    this.isAction = 'add'
  }

  filterItems(items: any) {
    this.data = items.map((obj: any) => ({
      ...obj,
      insurance_number:
        obj.insurance_number != null ? obj.insurance_number : '',
      monthly_quota: obj.monthly_quota != null ? obj.monthly_quota : '',
      reported_salary: obj.reported_salary != null ? obj.reported_salary : '',
      id: obj.id,
      name_client: obj.member_number.client_name,
      client_member_number: obj.member_number.member_number,
      start_date: Helpers.dateParse(obj.start_date),
      status_read: obj.status.status,
      automatic_charge_applies_read:
        obj.automatic_charge_applies == 1 ? 'Si' : 'No',
      automatic_charge_applies:
        obj.automatic_charge_applies == 1 ? true : false,
      dependent: obj.dependent == 1 ? true : false,
      tariff_type: obj.type_tariff != null ? obj.type_tariff.id : '',
      status: obj.status.id,
      next_payment_date:
        obj.account_balance && obj.monthly_quota != null
          ? Helpers.genNextPaymentDate(obj.account_balance, obj.monthly_quota)
          : new Date(),
    }))
  }

  async SearchFilter(search: string) {
    this.params = [] // se limpia el arreglo de params
    this.params.push({
      text: 'search', // nombre del parametro que se agrega a la url
      value: search, // valor del parametro
    })
    const currentPage = await (this.$refs['Paginator'] as any)['current'] //Se guarda la página actual
    await (this.$refs['Paginator'] as any).paginate(currentPage) // se ejecuta el metodo de paginar sobre la misma página actual pero ahora con los debidos parametros extras
  }
}
